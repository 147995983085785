import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store/root";
import App from "./App";
import GuestbookApp from "./guestbook/GuestbookApp";
import PhotoGalleryApp from "./photo_gallery/PhotoGalleryApp";
import CampaignApp from "./campaign/CampaignApp";
import BookApp from "./book/BookApp";
import ExitIntent from "./exit_intent/ExitIntent";
import * as Sentry from "@sentry/react";
import ErrorBoundary from "../components/common/ErrorBoundary";
import { ignoredErrors } from "../components/common/sentry-config";

if (import.meta.env.VITE_SENTRY_ENABLED == "true") {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_APP_ENV,
        release: window.sentryRelease,
        ignoreErrors: ignoredErrors,
        integrations: [
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
            Sentry.thirdPartyErrorFilterIntegration({
                behaviour: "drop-error-if-contains-third-party-frames",
                filter: ["tukios-websites-react"],
            }),
        ],

        // Performance Monitoring
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? 0,
        // Session Replay
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ?? 0,
    });

    // Add section tag
    Sentry.setTag("errorSource.name", "widget");
}

const app = document.getElementById("tukios-obituary-app");

ReactDOM.render(
    <ErrorBoundary
        beforeCapture={(scope) => {
            scope.setTag("errorSource.component", "PublicObituaryApp");
        }}
    >
        <Provider store={store}>
            <App data={app} />
        </Provider>
    </ErrorBoundary>,
    app
);

const guestbookApp = document.getElementById("tukios-obituary-guestbook");

if (guestbookApp) {
    const disableObituaryPosts =
        guestbookApp.hasAttribute("data-disable-posts") &&
        !/^(false|0|off|no)$/i.test(
            guestbookApp.getAttribute("data-disable-posts")
        );

    ReactDOM.render(
        <ErrorBoundary
            beforeCapture={(scope) => {
                scope.setTag("errorSource.component", "GuestbookApp");
            }}
        >
            <Provider store={store}>
                <GuestbookApp disablePosts={disableObituaryPosts} />
            </Provider>
        </ErrorBoundary>,
        guestbookApp
    );
}

const gallery = document.getElementById("tukios-obituary-gallery");

if (gallery) {
    ReactDOM.render(
        <ErrorBoundary
            beforeCapture={(scope) => {
                scope.setTag("errorSource.component", "PhotoGalleryApp");
            }}
        >
            <Provider store={store}>
                <PhotoGalleryApp />
            </Provider>
        </ErrorBoundary>,
        gallery
    );
}

const campaignApp = document.getElementById("tukios-obituary-campaigns");

if (campaignApp) {
    ReactDOM.render(
        <>
            <ErrorBoundary
                beforeCapture={(scope) => {
                    scope.setTag("errorSource.component", "CampaignApp");
                }}
            >
                <Provider store={store}>
                    <CampaignApp />
                </Provider>
            </ErrorBoundary>
        </>,
        campaignApp
    );
}

const bookApp = document.getElementById("tukios-obituary-book");

if (bookApp) {
    ReactDOM.render(
        <>
            <ErrorBoundary
                beforeCapture={(scope) => {
                    scope.setTag("errorSource.component", "ObituaryBookApp");
                }}
            >
                <Provider store={store}>
                    <BookApp />
                </Provider>
            </ErrorBoundary>
        </>,
        bookApp
    );
}

const exitIntent = document.getElementById("tukios-obituary-exit-intent-modal");

if (exitIntent) {
    ReactDOM.render(
        <ErrorBoundary
            beforeCapture={(scope) => {
                scope.setTag("errorSource.component", "ExitIntent");
            }}
        >
            <Provider store={store}>
                <ExitIntent />
            </Provider>
        </ErrorBoundary>,
        exitIntent
    );
}
