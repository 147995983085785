import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { classNames, filled, usePrevious, isSet, t } from '@/components/common/Helpers'
import { guestbookEntity } from '../store/root'
import { Dialog, Transition } from '@headlessui/react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import GuestbookItem from './GuestbookItem'
import GuestbookForm from './GuestbookForm'
import Skeleton from 'react-loading-skeleton'
import Masonry from 'react-masonry-css'
import { BsFacebook, BsTwitter } from 'react-icons/bs'
import { useForm } from 'react-hook-form'
import InfiniteScroll from 'react-infinite-scroller'
import PropTypes from 'prop-types'

function GuestbookApp({ disablePosts }) {

    const {
        guestbook,
        guestbookList,
        guestbookCreateLoading
    } = useSelector(state => state.guestbookReducer)

    const { obituary } = useSelector(state => state.obituaryReducer)

    const [obituaryPost, setObituaryPost] = useState(null)
    const [postCreated, setPostCreated] = useState(false)
    const [leadImage, setLeadImage] = useState('')
    const [adGroupFormLink, setAdGroupFormLink] = useState(null)

    const previous = usePrevious({ guestbookCreateLoading })

    const dispatch = useDispatch()

    const refresh = (page) => {
        dispatch(guestbookEntity.services.findAll({ ...(page && { page: page }) }, { append: true }))
    }

    const breakpointColumnsObj = {
        default: 3,
        768: 2,
        500: 1
    }
    const methods = useForm({
        defaultValues: {
            name: '',
            obituary_id: obituary?.id,
            organization_id: null,
            ad_group_id: null,
            ad_group_asset_id: null,
            email: '',
            phone: '',
        }
    })

    useEffect(() => {
        // const currentAsset = collect(obituary?.ad_group?.ad_group_assets).filter(ag => ag.type === AdGroupTypes?.Website?.Value).first()
        if (filled(obituary?.ad_group_banner)) {
            const url = obituary.ad_group_banner.asset_image
            // methods.setValue('ad_group_asset_id', currentAsset.id)
            if (filled(url)) {
                setLeadImage(url)
            } else {
                setLeadImage('')
            }
        } else {
            setLeadImage('')
        }
        // methods.setValue('obituary_id', obituary?.id)
        // methods.setValue('organization_id', obituary?.ad_group?.organization_id)
        // methods.setValue('ad_group_id', obituary?.ad_group?.id)
    }, [obituary])

    useEffect(() => {
        if (filled(obituary?.ad_group_banner)) {
            const params = new URLSearchParams()
            params.append('oid', obituary.id)

            if (guestbook?.name) {
                params.append('name', guestbook.name)
            }

            if (guestbook?.email) {
                params.append('email', guestbook.email)
            }
            setAdGroupFormLink(obituary.ad_group_banner.url + `?${params.toString()}`)
        }

        methods.setValue('name', guestbook?.name)
        methods.setValue('email', guestbook?.email)
    }, [guestbook])

    useEffect(() => {
        if (obituary && !guestbookCreateLoading && previous?.guestbookCreateLoading) {
            setPostCreated(true)
        }
    }, [obituary, guestbookCreateLoading])

    return (
        <>
            {obituary &&
                <>
                    {(!disablePosts && !obituary.app_in_maintenance_mode) && (
                        <div className="border border-gray p-8 rounded-md bg-white mb-8">

                            {!guestbookCreateLoading ? (
                                <>
                                    {postCreated ? (
                                        <div className={classNames('grid grid-cols-1 gap-8', filled(leadImage) ? 'md:grid-cols-2' : '')}>
                                            <div className="text-center italic text-xl font-serif">
                                                <p className="mb-2">{t.trans('guestbook.thank')}
                                                    {!guestbook?.is_approved &&
                                                        <span className="block text-lg">{t.trans('guestbook.review')}</span>
                                                    }
                                                </p>
                                                {guestbook &&
                                                    <>
                                                        <p className="text-sm uppercase mb-2">{t.trans('guestbook.preview')}</p>
                                                        <div className="flex justify-center font-sans">
                                                            <GuestbookItem post={guestbook} setObituaryPost={setObituaryPost} className="text-left w-full md:w-1/2 border border-gray-100 shadow not-italic" />
                                                        </div>

                                                        {guestbook.subscription_pending_verification &&
                                                            <>
                                                                <div className="pt-8">{t.trans('guestbook.check-email')}</div>
                                                                <p className="text-base font-sans not-italic text-gray-500">{t.trans('guestbook.confirm-sub')}</p>
                                                            </>
                                                        }
                                                    </>
                                                }
                                                {guestbook?.urls &&
                                                    <>
                                                        <div className="pt-8 pb-4">{t.trans('guestbook.share')}</div>
                                                        <div className="flex justify-center gap-x-4">
                                                            <a href={guestbook.urls.fb_share_link} rel="noreferrer" target="_blank">
                                                                <BsFacebook className="w-12 h-12 text-gray-400 hover:text-blue-700" />
                                                            </a>
                                                            <a href={guestbook.urls.twitter_share_link} rel="noreferrer" target="_blank">
                                                                <BsTwitter className="w-12 h-12 text-gray-400 hover:text-blue-400" />
                                                            </a>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            {filled(leadImage) &&
                                                <a href={adGroupFormLink} target="_blank" rel="noreferrer">
                                                    <img loading="lazy"
                                                        className="cursor-pointer h-full"
                                                        alt="Lead Generation Image"
                                                        src={leadImage}
                                                    />
                                                </a>
                                            }
                                        </div>
                                    ) : (
                                        <GuestbookForm />
                                    )}
                                </>
                            ) : (
                                <Skeleton height={25} count={3} />
                            )}
                        </div>
                    )}

                    {(!disablePosts && obituary.app_in_maintenance_mode) && (
                        <div className="flex flex-col items-center justify-center py-8 bg-gray-50 border border-gray-200 rounded-lg shadow-lg mb-8">
                            <p className="text-xl font-semibold text-gray-700">{t.trans('guestbook.unavailable')} </p>
                            <p className="mt-2 text-base text-gray-600 text-center">{t.trans('guestbook.unavailable-msg')}</p>
                        </div>
                    )}

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={refresh}
                        initialLoad={false}
                        hasMore={!isSet(guestbookList) || guestbookList.current_page < guestbookList.last_page}
                        loader={<Skeleton key={1} height={25} count={3} />}
                    >
                        <Masonry breakpointCols={breakpointColumnsObj}
                            className="twp-masonry-grid"
                            columnClassName="twp-masonry-grid_column"
                        >
                            {guestbookList?.data?.map(post => (
                                <GuestbookItem key={post.id} post={post} setObituaryPost={setObituaryPost} />
                            ))}

                        </Masonry>
                    </InfiniteScroll>

                    <Transition.Root show={obituaryPost !== null} as={Fragment}>
                        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setObituaryPost(null)}>
                            <div className="flex items-end justify-center min-h-screen pt-2 px-2 pb-16 text-center sm:block sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <div className="inline-block align-bottom bg-white rounded-lg pb-4 text-left overflow-hidden
                                    shadow-xl transform transition-all sm:my-8 sm:align-middle
                                    w-full sm:w-10/12 xl:max-w-screen-lg max-h-full">
                                        {obituaryPost !== null && (
                                            <div className="w-full h-128 max-h-full">
                                                <Carousel centerMode={false} showThumbs={false} showArrows={true} showIndicators={false} showStatus={false} infiniteLoop={true} autoPlay={false} interval={4000}>
                                                    {obituaryPost.obituary_assets.map((asset, count) => (
                                                        <div className="bg-gray-900 h-128" key={asset.id}>
                                                            <img loading='lazy' src={asset.asset.optimized} className="h-full w-full object-contain" style={{ width: 'auto' }} alt={`Guestbook gallery photo ${count + 1}: ${obituary.display_name} posted by ${obituaryPost.name}`} />
                                                        </div>
                                                    ))}
                                                </Carousel>

                                            </div>
                                        )}
                                        <div className="flex justify-end p-4">
                                            <button
                                                type="button"
                                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                onClick={() => setObituaryPost(null)}
                                            >
                                                Close
                                            </button>
                                        </div>

                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition.Root>
                </>
            }
        </>
    )
}

GuestbookApp.propTypes = {
    disablePosts: PropTypes.bool
}

export default GuestbookApp
