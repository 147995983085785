import {useSelector} from "react-redux";
import {useState} from "react";
import Modal from "@/public_obituary/book/Modal";
import FlipBook from "@/public_obituary/book/flip-book/FlipBook";
import BookBlock from "@/public_obituary/book/BookBlock";

export default function BookApp() {
    /** @type {MutableRefObject<HTMLIFrameElement|null>} */
    const [isModalOpen, setIsModalOpen] = useState(false);

    const {
        obituary
    } = useSelector(state => state.obituaryReducer);

    if (!obituary) return null;

    return (
        <>
            <BookBlock onViewBook={() => setIsModalOpen(true)}/>

            <Modal
                obituary={obituary}
                onClose={() => setIsModalOpen(false)}
                isOpen={isModalOpen}
            >
                <FlipBook obituary={obituary} active={isModalOpen} />
            </Modal>
        </>
    )
}
